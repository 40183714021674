<template>
  <div class="bbb">
    <van-nav-bar
      title="尚云方统筹问诊"
      />
    <div id="Interrogation"  class=" time_slot">
        <div class="header_img bpd">
          <!-- <img src="../assets/image/index_header.png" alt=""> -->
          <img src="../assets/image/index_header.jpg" alt="">
          <van-button v-if="PharmacyInfo.consultType.length == 0" @click="tz(undefined)" class="margin_top"  color="linear-gradient(to right, #02d3d9, #09e0c0)" block>统筹问诊申请</van-button>
          <div v-else style="display: flex;">
            <van-button v-for="(item) of PharmacyInfo.consultType" @click="tz(item)" class="margin_top" style="margin: 0 1px;"  color="linear-gradient(to right, #02d3d9, #09e0c0)" block  :icon="consultTypeIcon[item*1]">{{ consultType[item*1] }}</van-button>
          </div>
          <div class="yaowu margin_top" style="justify-content:flex-start;align-items: center;height: 16px;">
            <p class="yaowu_title">常用药物:&nbsp;</p>
            <p v-for="(item,index) of CommonlyUsedDrugs">{{ item.name.length <=5 ? item.name :  item.name.slice(0,5)+'...' }}</p>
            <p v-if="CommonlyUsedDrugs.length == 0">无</p>
          </div>
          <div  class="yaowu margin_top" style="justify-content:flex-start;align-items: center;height: 16px;">
            <p class="yaowu_title">当前药店:&nbsp;</p>
            <p >{{ PharmacyInfo.name ? PharmacyInfo.name : '暂未选择药店' }}</p>
          </div>
        </div>
        <div class="scyy margin_top bpd">
          <div class="title"><span class="title_icon">| </span>上次用药</div>
          <div class="scyy_btn margin_top padding-4">
            <van-button v-for="(item,index) of LastUsedDrugs" plain type="danger" size="small">{{ item.name.length <=3 ? item.name :  item.name.slice(0,3)+'...' }}</van-button>
            <van-button v-if="LastUsedDrugs.length == 0" plain type="danger" size="small">无</van-button>
          </div>
        </div>
        

        
        
        <!-- <div class="zxxx margin_top bpd">
          <div class="title"><span class="title_icon">| </span>最新消息</div>
          <div @click="info(0)">
            <div class="zxxx_nr padding-4">
            <div class="zxxx_txt">
              <p class="zxxx_title">优质服务我们在行动」...</p>
              <p>近日，解放军总医院第七医学中心...</p>
              <p class="zxxx_time">2024/03/05</p>
            </div>
            <div class="zxxx_img">
              <img src="https://pic.rmb.bdstatic.com/bjh/news/c064b7a8019fc0f3d736948ddd2e2267.jpeg" alt="">
            </div>

            </div>
            <van-divider :margin="12"></van-divider>
          </div>
          
          <div @click="info(1)">
            <div class="zxxx_nr padding-4">
            <div class="zxxx_txt">
              <p class="zxxx_title">六个一健康宣教系列活动...</p>
              <p>为深入贯彻落实党的二十大精神，...</p>
              <p class="zxxx_time">2023/04/09</p>
            </div>
            <div class="zxxx_img">
              <img src="https://pic.rmb.bdstatic.com/bjh/news/bbd939e8eb7747b50d24a57b6933a274.jpeg" alt="">
            </div>

            </div>
            <van-divider :margin="12"></van-divider>
          </div>
        </div> -->
      <footer_/>
    </div>
    
  </div>
</template>
<script>
import footer_ from '../components/footer.vue'
import { Loading } from 'element-ui';
import module_function from '../utils/login'
import mixin from "@/utils/mixin";
import wx from '../assets/js/jweixin-1.6.0.js'
import util from '../assets/js/util.js'
import sha1 from 'js-sha1'
export default {
  mixins: [mixin],
  components: {
    footer_,
  },
  data() {
    return {
      LastUsedDrugs:[
     
      ],
      CommonlyUsedDrugs:[
 
      ],
      PharmacyInfo:{
        consultType:[]
      },
      consultType:[
        '',
        '图文问诊',
        '音频问诊',
        '视频问诊',
      ],
      consultTypeIcon:[
        '',
        'photo',
        'phone',
        'video',
      ]
    }
  },
  methods: {
    tz(item){
      if(this.isTimepharmacy_id_time()){
        this.iftime(item)
        return
      }
      let that = this
      if(localStorage.getItem('userinfo')&&localStorage.getItem('userrole_id') ){
        if(!sessionStorage.getItem('pharmacy_id')){
          
          this.$alert('请扫描药店二维码后再次申请问诊', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: action => {
           that.wxcode(item)
          }
          });
        }else{
          this.tuPres(item)
        }
      }else{
        this.$router.push('/login')
      }
    },
    tuPres(item){
      this.$api.article.getihname().then(res=>{
            let ihname = res.data.result
            localStorage.setItem('ih_type',res.data.result.name)
            this.$api.article.postihuserinfo(res.data.result.name).then(res=>{
              console.log(res)
              if(res.data.code=='200'){
                 if(ihname.name == 'LodYXHospital'){
                    this.$router.push('/Drugs')
                  }else{
                    if(item){
                      this.$router.push({
                        name:'Pres',
                        query:{
                          consultType:item,
                        }
                    })
                    }else{
                      this.$router.push('/Pres')
                    }
                    
                  }
              }
            })
           
          })
    },
    objKeySort(arys) {
        //先用Object内置类的keys方法获取要排序对象的属性名数组，再利用Array的sort方法进行排序
        var newkey = Object.keys(arys).sort()
        console.log('newkey=' + newkey)
        var newObj = '' //创建一个新的对象，用于存放排好序的键值对
        for (var i = 0; i < newkey.length; i++) {
            //遍历newkey数组
            newObj += [newkey[i]] + '=' + arys[newkey[i]] + '&'
        }
        return newObj.substring(0, newObj.length - 1)
    },
    getPatient(){
      // this.$api.article.InternetHospitalGet(
      //   {
      //       search_db:'Patient',
      //       ih_type:localStorage.getItem('ih_type')
      //   },
      //   {
      //       user_id:JSON.parse(localStorage.getItem('user')).user_id,
      //   }
      // ).then(res=>{
      //   console.log(res)
      //   // if(){}
      //   // if(res.data.result.data){
      //   //   this.$router.push('/Pres')
      //   // }else{
      //   //   this.AuthPresUrl()
      //   // }
      // })
    },
    //获取就诊授权连接
    AuthPresUrl(){
      this.$api.article.InternetHospitalGet(
          {
              search_db:'AuthPresUrl',
              ih_type:localStorage.getItem('ih_type')
          },
          {
              user_id:JSON.parse(localStorage.getItem('user')).user_id,
          }
      ).then(res=>{
          console.log(res)
          const h = this.$createElement;
          this.$msgbox({
              title: '消息',
              message: h('p', { style: 'text-align: center' }, [
                  h('span', null, '当前就诊人尚未获得医保授权'),
                  h('br'),
                  h('i', { style: 'font-weight: 800' }, '请点击“授权”按钮进行授权'),
                  h('br'),
                  h('i', null, '授权完成后，'),
                  h('i', { style: 'font-weight: 400' }, '请再次进入此页面')
              ]),
              showCancelButton: true,
              confirmButtonText: '授权',
              cancelButtonText: '取消',
              beforeClose: (action, instance, done) => {
              if(action == 'confirm'){
                  window.location.href= res.data.result.data.presUrl
              }else{
                  done();
              }
              }
          })
          
      })
    },
    loadList(){
      if(sessionStorage.getItem('pharmacy_id')){
        let params = {
          search_db:'Patient',
          query_type:'equal',
          start:'0',
          length:'2'

        }
        let data = {
            query:{
              user_account:JSON.parse(localStorage.getItem('user')).user_account,
            },
        }
        this.$api.article.query(params,data).then(res => {
            res.data.result.forEach(element => {
              this.$api.article.GetPresMedicine(element.patient_id).then(res=>{
                res.data.result.forEach(element => {
                  if(this.CommonlyUsedDrugs.length == 1){
                    return
                  }
                  this.CommonlyUsedDrugs.push(element)
                });
              })
            });
          
        })
        this.$api.article.query(
          {
            search_db:'Pres',
            query_type:'first',
          },
          {
            query:{
              found_id:localStorage.getItem('userrole_id'),
              pharmacy_id:sessionStorage.getItem('pharmacy_id')
            },
          }

        ).then(res => {
          console.log(res)
            if(res.data.result.medicine && res.data.result.medicine!=='None'){
              res.data.result.pres_body = JSON.parse(res.data.result.pres_body)
              if(res.data.result.pres_body.Drugs.length>3){
                res.data.result.pres_body.Drugs.length = 3
              }
              this.LastUsedDrugs = res.data.result.pres_body.Drugs
              console.log(this.LastUsedDrugs)
            }
        })



        
      }

    
        
    },

    getPharmacy(){
      this.$api.article.query(
          {
            search_db:'Pharmacy',
            query_type:'first',
          },
          {
            query:{
              id:sessionStorage.getItem('pharmacy_id')
            },
          }

        ).then(res => {
          console.log(res.data.result)
          if(res.data.result.consultType.indexOf('[') !== -1){
            res.data.result.consultType = JSON.parse(res.data.result.consultType)
          }else{
            res.data.result.consultType = []
          }
          this.PharmacyInfo = res.data.result
          localStorage.setItem('PharmacyInfo',JSON.stringify(res.data.result))
        })
    },
    info(id){
      this.$router.push({
        name:'info',
        query:{
          id
        }
      })
    },
    isTimeExpired(time1, time2) {
      // 将日期对象转换为毫秒数
      var time2 = new Date();
      var timestamp1 = time1.getTime();
      var timestamp2 = time2.getTime();
      // var eightHoursInMilliseconds = 8 * 60 * 60 * 1000;
      // 如果时间1的时间戳大于时间2的时间戳，表示时间1已经超过了时间2
      return timestamp1 > timestamp2;
    },
    wxcode(item){
      let that = this
      that.$api.article.getticket().then(res=>{
            let fullUrl = window.location.href;
            let url = fullUrl.split('#')[0];
            let timestamp = new Date().getTime()
            let sortStr = that.objKeySort({
                jsapi_ticket:res.data.result.ticket,
                noncestr:`${timestamp}`,
                timestamp: Math.ceil(timestamp/1000),
                url
            })
            wx.config({
              // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: 'wx674869ee215ee6e7', // 必填，公众号的唯一标识
              timestamp: Math.ceil(timestamp/1000), // 必填，生成签名的时间戳
              nonceStr: `${timestamp}`, // 必填，生成签名的随机串
              signature: sha1(sortStr),// 必填，签名
              jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
            });
            wx.ready(function(res){
              wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: function (res) {
                var url = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                if(url.indexOf('pharmacy_id=') !== -1){
                  let pharmacy_id = url.split('pharmacy_id=')[1].split('&')[0]
                  if(pharmacy_id.length ==36){
                    sessionStorage.setItem('pharmacy_id', pharmacy_id)
                    var d = new Date().getTime()
                    sessionStorage.setItem('pharmacy_id_time',util.formatDate(d))
                    that.tuPres(item)
                    
                 
                  }else{
                    that.$alert('您的药店码不正确，请扫描正确的药店码', '提示', {
                      confirmButtonText: '确定',
                      type: 'warning',
                    });
                  }
                }else{
                  that.$alert('请扫描正确的药店码后，进行问诊', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                  });
                }
                
              }
            })
            });
      })
    },
    signature(){
      
    },
    isTimepharmacy_id_time(){
      var d = new Date().getTime()
      if(!sessionStorage.getItem('pharmacy_id_time')){
        sessionStorage.setItem('pharmacy_id_time',util.formatDate(d))
      }
      return util.getFutureDate(   util.formatDate(d),sessionStorage.getItem('pharmacy_id_time'),1 )
    },
    iftime(item){
      let that = this
      if(this.isTimepharmacy_id_time()){
        sessionStorage.removeItem('pharmacy_id');
        sessionStorage.removeItem('pharmacy_id_time');
        localStorage.removeItem('pharmacy_id')
      }
      this.$alert('请扫描药店二维码后再次申请问诊', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        callback: action => {
          that.wxcode(item)
        }
      });
    }
  },
  created() {
    
    var d = new Date().getTime()
    sessionStorage.removeItem('Drugs');
    sessionStorage.removeItem('Current');
    sessionStorage.removeItem('disease');
    sessionStorage.removeItem('patientId');
    sessionStorage.removeItem('family');
    
    if(this.$route.query.pharmacy_id){
      localStorage.setItem('pharmacy_id', this.$route.query.pharmacy_id);
      sessionStorage.setItem('pharmacy_id_time',util.formatDate(d))
    }
    if(localStorage.getItem('pharmacy_id')){
      sessionStorage.setItem('pharmacy_id', localStorage.getItem('pharmacy_id'));
    }
    if(this.$route.query.wechat_code){
      sessionStorage.setItem('wechat_code', this.$route.query.wechat_code)
    }
    if(this.$route.query.auth_phone && this.$route.query.auth_code){
      this.$message({
        message: '自动登录中',
        type: 'success'
      });
      module_function.sms_login(this.$route.query.auth_phone,this.$route.query.auth_code,'/index','usercreated').then(res=>{
				// this.IMSIG('/')
			})
    }
    if(localStorage.getItem('user')){
      this.loadList()
    }
    

    if(this.$route.query.type && this.$route.query.type == 'wechat'){
      window.location.href= 'https://cloud.net-sun.com/manual/img/wechat.png'
    }
    
  },
  mounted(){
    if(sessionStorage.getItem('pharmacy_id')){
      this.getPharmacy()
    }

  }
}
</script>
<style  lang="less">
.van-button--normal{
  font-size: 0.8rem !important;
}
.padding-4{
  padding:0 4.9%;
}
.padding-l-20{
  padding-left: 20px;
}
.padding_r_0{
  padding-right: 0 !important;
}
.padding_lr_10{
  padding: 0 20px;
}
.color_20d8de{
      color: #20d8de;
  }
.display{
  display: flex;
}
.jcsb{
  justify-content: space-between;
}
.aic{
  align-items: center;

}
.van-nav-bar{
  background: rgba(255, 255, 255, 0) !important;
}
.van-nav-bar__title{
  color: #fff !important;
  font-weight: 600 !important;
}
.title{
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.margin_top{
  margin-top: 0.9rem !important;
}
.bpd{
  background: #fff;
  padding: 10px;
  border-radius: 6px;
}
#Interrogation{
  height: calc(100% - 49px);
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 6px;
  overflow: auto;
}
.header_img{
  width: 100%;
  height: 20rem;
  img{
    width: 100%;
    height: 10rem;
  }
  .yaowu{
    margin: 0 auto;
    display: flex;
    color: #999999;
    font-size: 14px;
    justify-content: space-between;
    .yaowu_title{
      color: #000;
    }
  }
  button{
    font-size: 1rem !important;
    height: 4rem;
  }
}
.scyy{
  background: linear-gradient(to right,#fdd8d3,#fee4dd,#fff2e8) !important;
  height: 7.4rem;

  .scyy_btn{
    display: flex;
    // margin-left: 10px;
    // justify-content: space-between;
    .van-button--small{
      margin-right: 10px;
      height: 2rem;
    }
  }
}
.zxxx_nr{
  display: flex;
  .zxxx_txt{
    width: 60%;
    .zxxx_title{
      font-size: 15px;
      color: #000;
      font-weight: 600;
      line-height: 20px;
      margin-top: 10px;
    }
    p{
      font-size: 10px;
      line-height: 20px;
    }
    .zxxx_time{
      margin-top: 10px !important;
    }
  }
  .zxxx_img{
    width: 40%;
    height: 5rem;
    border-radius: 2px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>